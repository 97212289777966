import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import "../src/CSS/custom.css";
import OrderCancel from "./components/OrderCancel";
import OrderDelivered from "./components/OrderDelivered";
import NotFound from "./components/NotFound";
import OrderStatus from "./components/OrderStatus";
import OrderPickedUp from "./components/OrderPickedUp";
import "bootstrap/dist/css/bootstrap.min.css";
import RobotStatus from "./components/RobotStatus";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<NotFound />} />
        <Route path="/robot/:robotId" element={<RobotStatus />} />
        <Route path="/order/:orderID" element={<OrderStatus />} />
        <Route path="/ordercancel/:orderID" element={<OrderCancel />} />
        <Route path="/orderdelivered/:orderID" element={<OrderDelivered />} />
        <Route path="/orderpickedup/:orderID" element={<OrderPickedUp />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
