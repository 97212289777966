import React, { useEffect, useState, lazy, Suspense } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MapboxRobot from "./MapboxRobot";
import Instruction from "../modals/Instruction";
const Watchvideo = lazy(() => import("../modals/Watchvideo"));

function RobotTracking({
  maxBoundCordinates,
  robotDetails,
  robotActiveMission,
  isLoading,
}) {
  const [openthree, setopenthree] = useState(false);
  const handleOpenthree = () => setopenthree(true);
  const [openfour, setopenfour] = React.useState(false);
  const handleOpenfour = () => {
    setopenfour(true);
  };

  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
  };

  const loaderStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #000",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  const keyframesAnimation = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  let mapheight = "327px";

  useEffect(() => {
    if (document.getElementById("Estimated_time")) {
      var offsetHeight = document.getElementById("Estimated_time").offsetHeight;
    }

    mapheight = mapheight - offsetHeight + "px";
  }, [mapheight]);

  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header />
        <div className="Congratulation-Main-wrapper">
          <div className="flex flex-col h-full">
            <div
              className="h-full bg-white Congralutions_page_map_div"
              id="mapDiv"
            >
              {Object.keys(maxBoundCordinates).length ? (
                <MapboxRobot
                  maxBoundCordinates={maxBoundCordinates}
                  robotLocation={robotDetails?.robotStatus}
                  robotActiveMission={robotActiveMission}
                />
              ) : null}
            </div>
            <div className="flex flex-col gap-y-3 rounded-t-full py-3 px-4">
              <div className="flex border border-[#E6E6E6] py-3 px-2 gap-x-2 rounded w-full">
                <div className="bg-[#F1F0F5] py-1.5 px-3 rounded flex">
                  <svg
                    className="self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="32.956"
                    viewBox="0 0 15 32.956"
                  >
                    <g
                      id="noun-info-2096294"
                      transform="translate(-222.749 -0.058)"
                    >
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M233.156,146.716c2.733,0,4.074,1.742,3.293,4.627-.557,2.088-1.363,4.113-2.1,6.151-.813,2.266-1.676,4.518-2.5,6.78a6.843,6.843,0,0,0-.365,1.475,1,1,0,0,0,.244.813.938.938,0,0,0,.843,0,6.254,6.254,0,0,0,1.35-1.034c.846-.86,1.646-1.782,2.466-2.654.247-.26.5-.724.988-.418a.863.863,0,0,1,.171,1.281,12.978,12.978,0,0,1-1.291,1.821,15.992,15.992,0,0,1-7.9,5.216,4.922,4.922,0,0,1-2.914.079h0a2.8,2.8,0,0,1-1.367-.915,3.229,3.229,0,0,1-.514-2.164,6.291,6.291,0,0,1,.389-2.088s1.1-2.914,2.634-7.1c1.317-3.6,1.459-4.192,1.884-5.308.211-.553.435-1.1.659-1.646.161-.441.352-.988-.115-1.248a1.433,1.433,0,0,0-1.245.138,15.8,15.8,0,0,0-1.976,1.8c-.616.629-1.159,1.317-1.758,1.976-.254.273-.553.55-.968.24s-.356-.659-.161-1.06h0a1.183,1.183,0,0,1,.122-.23c2.305-3.1,4.979-5.684,8.986-6.385a4.815,4.815,0,0,1,1.149-.148Z"
                        transform="translate(0 -138.034)"
                      />
                      <path
                        id="Path_86"
                        data-name="Path 86"
                        d="M301.2,3.594a4.192,4.192,0,0,1-5.713,3.846,3.518,3.518,0,0,1-1.867-4.248h0A4.281,4.281,0,0,1,298.3.094a3.312,3.312,0,0,1,2.9,3.5Z"
                        transform="translate(-66.577 0)"
                        fill="#00a16f"
                      />
                    </g>
                  </svg>
                  {/* <TiInfoLarge className="h-12 w-12 self-center" /> */}
                </div>
                <div className="flex flex-col gap-y-1 w-full">
                  <p className="self-start font-bold text-sm">OTTOBOT</p>
                  <div className="flex text-[#00A16F] justify-between">
                    <p className="font-bold text-xs self-center">
                      {robotDetails?.robotStatus?.robotId}
                    </p>
                    {!!Number(process.env.REACT_APP_SHOW_INSTRUCTIONS) && (
                      <button
                        onClick={handleOpenthree}
                        className="text-xs p-1.5 bg-[#F1F0F5] font-bold rounded"
                      >
                        VIEW INSTRUCTIONS
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Instruction
        openthree={openthree}
        setopenthree={setopenthree}
        handleOpenthree={handleOpenthree}
      />
      <Suspense
        fallback={
          <div style={loaderContainerStyle}>
            <style>{keyframesAnimation}</style>
            <div style={loaderStyle}></div>
          </div>
        }
      >
        {openfour && (
          <Watchvideo
            openfour={openfour}
            setopenfour={setopenfour}
            handleOpenfour={handleOpenfour}
          />
        )}
      </Suspense>
      <Footer />
    </>
  );
}

export default RobotTracking;
