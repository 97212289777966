import React, { useState, useEffect } from "react";
import {
  getGeofenceByFleetId,
  getFleetConfig,
  getRobotStatus,
  getActiveMission,
  getFleetConfigByFleetId,
} from "../API";
import { findNorthWestAndSouthEast } from "../utils";

import { useParams } from "react-router-dom";
import RobotTracking from "./RobotTracking";
function RobotStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [robotDetails, setRobotDetails] = useState(null);
  const [robotActiveMission, setRobotActiveMission] = useState([]);
  const { robotId } = useParams();
  const [maxBoundCordinates, setBoundCordinates] = useState({});
  useEffect(() => {
    const intervalId = setInterval(() => {
      getRobotStatus(robotId)
        .then((res) => {
          getGeofenceByFleetId(res?.data?.data?.robotStatus?.fleetId).then(
            (res) => {
              setBoundCordinates(findNorthWestAndSouthEast(res.data.geofence));
            }
          );
          setRobotDetails(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(true);
          console.log("Error ", err);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getActiveMission(robotId)
        .then((response) => {
          setRobotActiveMission(response?.data);
        })
        .catch((err) => {
          setIsLoading(true);
          console.log("Error ", err);
        });
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const fetchFleetConfig = async () => {
      try {
        const response = await getFleetConfigByFleetId(
          robotDetails?.robotStatus?.fleetId
        );
        const data = response?.data?.data;
        localStorage.setItem("fleetConfig", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (robotDetails) {
      fetchFleetConfig();
    }
  }, [robotDetails]);

  return (
    <div>
      {/* {false ? (
        <Loader />
      ) : ( */}
      <RobotTracking
        maxBoundCordinates={maxBoundCordinates}
        robotDetails={robotDetails}
        robotActiveMission={robotActiveMission}
        isLoading={isLoading}
      />
      {/* )} */}
    </div>
  );
}

export default RobotStatus;
